import React from "react"
import { Link } from "gatsby"

const Pagination = ({ 
  paginationCurrentPage,
  paginationNumPages,
  paginationPrevTo,
  paginationNextTo
}) => (
  
  <span id="pagination_1662985214106" className="Panigation_Card">
    {paginationCurrentPage > 1 ? (
      <Link
        id="pagination_1662985215657"
        className="Panigation_Card__Link"
        title="Go to previous page"
        to={paginationPrevTo}
      >
        ←Prev
      </Link>
    ) : <span id="pagination_1662985216864"/>}
    &nbsp;Page {paginationCurrentPage} of {paginationNumPages}&nbsp;
    {paginationCurrentPage < paginationNumPages ? (
      <Link
        id="pagination_1662985218400"
        className="Panigation_Card__Link"
        title="Go to next page"
        to={paginationNextTo}
      >
        Next→
      </Link>
    ) : <span id="pagination_1662985222456"/>}
  </span>
)

export default Pagination